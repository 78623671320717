import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: Poppins, sans-serif;
  }
  body {
    margin:0;
    padding-top:125px;
    @media (max-width:470px) {
      padding-top:85px;
    }
  }
  .scroll-header {
    box-shadow: rgb(6 13 54 / 18%) 4px 14px 35px;
    @media (max-width:768px) {
      box-shadow:none;
    }
  }
  
`;
