import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const preorder = true;

const navLinks = [
  {
    name: 'Home',
    linkTo: '/#home'
  },
  {
    name: 'Shop',
    linkTo: 'https://shop.getsajdah.com/'
  },
  {
    name: 'Features',
    linkTo: '/#features'
  },
  {
    name: 'Testimonials',
    linkTo: '/#testimonials'
  },
  {
    name: 'Our Team',
    linkTo: '/#our-team'
  },
  {
    name: 'Contact Us',
    linkTo: '/#contact-us'
  },
  {
    name: 'Blog',
    linkTo: '/blog'
  }
];

const Header = () => {
  const [isScrolled, setScrolled] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const isBrowser = typeof window !== `undefined`;
  const handleScroll = e => {
    const bodyOffset = document.body.getBoundingClientRect();

    if (bodyOffset.top < 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    if (!isBrowser) return null;
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <HeaderWrapper className={isScrolled ? ' scroll-header' : ''}>
      <HeaderContainer>
        <Link to="/">
          <LogoContainer>
            <Logo src="/logo-sajdah.svg" alt="sajdah-logo" />
          </LogoContainer>
        </Link>

        <NavLinks navbarOpen={navbarOpen} onClick={() => setNavbarOpen(!navbarOpen)}>
          {navLinks.map((navLink, index) => {
            return (
              <StyledLink key={index} to={navLink.linkTo}>
                {navLink.name}
              </StyledLink>
            );
          })}
        </NavLinks>
        <CTAIconsWrapper>
          {preorder && (
            <ButtonLink href="https://shop.getsajdah.com/">
              <ButtonCta>Buy Now</ButtonCta>
            </ButtonLink>
          )}
          {!preorder && (
            <DownloadIconsWrapper>
              <a
                href="https://play.google.com/store/apps/details?id=com.getsajdah.sajdah.android"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/google-play.svg" alt="Get Sajdah from Google Play Store" />
              </a>
              <a
                href="https://apps.apple.com/us/app/sajdah-prayer-guide-times/id1557291793"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/app-store.svg" alt="Get Sajdah from Apple App Store" />
              </a>
            </DownloadIconsWrapper>
          )}
        </CTAIconsWrapper>
        <Toggle navbarOpen={navbarOpen} onClick={() => setNavbarOpen(!navbarOpen)}>
          {navbarOpen ? <Hamburger open /> : <Hamburger />}
        </Toggle>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

const ButtonLink = styled.a`
  text-decoration: none;
`;

const ButtonCta = styled.button`
  padding: 20px 35px;
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  text-transform: uppercase;
  background-color: #008e8e;
  outline: none;
  border: none;
  cursor: pointer;
`;

const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 126px;
  background-image: linear-gradient(to left, #eaf9f6 0%, #ffffff 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  top: 0;
  @media (max-width: 470px) {
    height: 85px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1203px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  @media (max-width: 960px) {
    max-width: 80%;
  }

  @media (max-width: 470px) {
    max-width: 90%;
  }
`;

const NavLinks = styled.div`
  width: 100%;
  max-width: 662px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1280px) {
    max-width: 580px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    background-color: #fff;
    transition: all 0.3s ease-in;
    left: 0;
    top: ${props => (props.navbarOpen ? '0' : '-150%')};
    height: 100%;
    overflow: auto;
    padding-top: 15vh;
    max-width: 100%;
  }
`;

const CTAIconsWrapper = styled.div`
  width: 100%;
  max-width: 238px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    height: auto;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
  @media (max-width: 960px) {
    justify-content: center;
  }
`;

const DownloadIconsWrapper = styled.div`
  width: 100%;
  max-width: 238px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    height: auto;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;

const LogoContainer = styled.div`
  @media (max-width: 470px) {
    max-width: 45px;
    img {
      max-width: 45px;
    }
  }
`;

const Logo = styled.img``;

const StyledLink = styled(AnchorLink)`
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  animation: all 0.2s ease-in-out;
  :hover {
    color: #008e8e;
  }
  @media (max-width: 1280px) {
    font-size: 15px;
  }
  @media (max-width: 768px) {
    font-size: 32px;
    margin-bottom: 24px;
  }
  @media (max-width: 470px) {
    font-size: 24px;
    margin-bottom: 12px;
  }
`;

const Hamburger = styled.div`
  background-color: #292929;
  width: ${props => (props.open ? '24px' : '16px')};
  margin-left: ${props => (props.open ? '0' : '8px')};
  height: 2px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? 'rotate(-45deg)' : 'inherit')};
  @media (max-width: 470px) {
    background-color: ${props => (props.open ? '  #292929 !important' : 'white')};
  }
  ::before,
  ::after {
    width: 24px;
    height: 2px;
    background-color: #292929;
    content: '';
    position: absolute;
    transition: all 0.3s linear;
    right: 0;
    @media (max-width: 470px) {
      width: 22px;
      height: 2px;
      background-color: ${props => (props.open ? ' #292929 !important' : 'white')};
    }
  }

  ::before {
    right: 0;
    transform: ${props => (props.open ? 'rotate(-90deg) translate(-6px, 0px)' : 'rotate(0deg)')};
    top: -6px;
    @media (max-width: 470px) {
      top: -6px;
      transform: ${props => (props.open ? 'rotate(-90deg) translate(-6px, 0px)' : 'rotate(0deg)')};
    }
  }

  ::after {
    opacity: ${props => (props.open ? '0' : '1')};
    transform: ${props => (props.open ? 'rotate(90deg) ' : 'rotate(0deg)')};
    top: 6px;
    @media (max-width: 470px) {
      top: 6px;
      transform: ${props => (props.open ? 'rotate(-90deg) translate(6px, 0px)' : 'rotate(0deg)')};
    }
  }
  @media (max-width: 470px) {
    width: 22px;
    height: 2px;
    margin-right: 16px;
    margin-bottom: 8px;
  }
`;

const Toggle = styled.div`
  display: none;
  height: 80px;
  cursor: pointer;
  padding: 0 24px;
  position: relative;
  z-index: 1001;

  @media (max-width: 768px) {
    display: flex;
    padding: 0 0 0 24px;
    height: 40px;
  }
  @media (max-width: 470px) {
    padding: 0;
    margin-right: -8px;
    height: 33px;
    div {
      background-color: #292929;
      :after {
        background-color: #292929;
      }
      :before {
        background-color: #292929;
      }
    }
  }
`;

export default Header;
