import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Footer = () => (
  <FooterWrapper>
    <FooterContainer>
      <SubscribeWrapper>
        <FormInfo>
          <TitleForm>Subscribe to our newsletters</TitleForm>
          <DescriptionForm>
            Subscribe here to receive the latest information on special offers and courses, plus our
            monthly newsletter.
          </DescriptionForm>
        </FormInfo>
        <FormWrapper>
          <Form
            id="subscribe-form-homepage-footer"
            method="post"
            action="https://getsajdah.us5.list-manage.com/subscribe/post"
          >
            <input type="hidden" name="u" value="b1c1bdd42683574d15089cb6d" />
            <input type="hidden" name="id" value="a590d16f80" />
            <StyledInput placeholder="Enter your email" name="EMAIL" type="email" required />
            <StyledButton type="submit" name="subscribe">
              Subscribe
            </StyledButton>
          </Form>
        </FormWrapper>
      </SubscribeWrapper>
      <NavFooter>
        <FooterColumn>
          <Link to="/">
            <Logo src="/sajdah-white.png" alt="sajdah-logo" />
          </Link>
          <SocialIcons>
            <SocialIcon
              href="https://www.facebook.com/GetSajdah"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/facebook.svg" alt="Follow Sajdah on Facebook" />
            </SocialIcon>
            <SocialIcon
              href="https://www.linkedin.com/company/thakaatechnologies"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/linkedin.svg" alt="Follow Thakaa Technologies on LinkedIn" />
            </SocialIcon>
            <SocialIcon
              href="https://twitter.com/GetSajdah"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/twitter-icon.svg" alt="Follow Sajdah on Twitter" />
            </SocialIcon>
            <SocialIcon
              href="https://www.instagram.com/GetSajdah/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/instagram-logo.svg" alt="Follow Sajdah on Instagram" />
            </SocialIcon>
            <SocialIcon
              href="https://www.youtube.com/GetSajdah"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/youtube.svg" alt="Follow Sajdah on YouTube" />
            </SocialIcon>
          </SocialIcons>
        </FooterColumn>
        <FooterColumn>
          <TitleFooterNav>Information</TitleFooterNav>
          <ListNavs>
            <ListItem to="/faq">F.A.Q</ListItem>
            <ListItem to="https://support.getsajdah.com/">Help Desk</ListItem>
            <ListItem to="/press-kit">Press Kit</ListItem>
            <ListItem to="/privacy">Privacy Policy</ListItem>
            <ListItem to="/terms">Terms of Use</ListItem>
          </ListNavs>
        </FooterColumn>
        <FooterColumn>
          <TitleFooterNav>The Company</TitleFooterNav>
          <ListNavs>
            <ListItem to="/#home">Home</ListItem>
            <ListItem to="https://shop.getsajdah.com/">Shop</ListItem>
            <ListItem to="https://console.getsajdah.com/">Console</ListItem>
            <ListItem to="/#features">Features</ListItem>
            <ListItem to="/#media">Media</ListItem>
            <ListItem to="/#our-team">Our Team</ListItem>
            <ListItem to="/#testimonials">Testimonials</ListItem>
            <ListItem to="/#contact-us">Contact Us</ListItem>
          </ListNavs>
        </FooterColumn>
        <FooterColumn>
          <TitleFooterNav>Contact Us</TitleFooterNav>
          <ContactItem>
            <ContactIcon src="/point.png" alt="point-icon" />
            <TextContact>
              Thakaa Technologies QSTP-LLC, Unit 107-03A - Incubator, Tech 2 Building East Wing,
              Qatar Science and Technology Park.
            </TextContact>
          </ContactItem>
          <ContactItem>
            <ContactIcon src="/mail.png" alt="mail-icon" className="mail" />
            <TextContact>
              <a href="mailto:hello@getsajdah.com"> hello@getsajdah.com </a>
            </TextContact>
          </ContactItem>
        </FooterColumn>
      </NavFooter>
      <FooterFooter>© Copyright 2021 Thakaa Technologies QSTP-LLC.</FooterFooter>
    </FooterContainer>
  </FooterWrapper>
);

const FooterWrapper = styled.div`
  width: 100%;
  padding: 64px 0 54px;
  background-color: #1b2436;
  @media (max-width: 768px) {
    padding: 32px 0 27px;
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  max-width: 1202px;
  margin: 0 auto;
`;

const SubscribeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 82px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  @media (max-width: 1280px) {
    max-width: 95%;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    max-width: 720px;
    margin: 0 auto;
    padding-bottom: 40px;
  }
  @media (max-width: 470px) {
    flex-direction: column;
  }
`;

const FormInfo = styled.div`
  width: 100%;
  max-width: 510px;
  @media (max-width: 768px) {
    max-width: 320px;
  }
  @media (max-width: 470px) {
    max-width: 90%;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  max-width: 578px;
  @media (max-width: 470px) {
    max-width: 90%;
  }
`;

const TitleForm = styled.h3`
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 25px;
  @media (max-width: 470px) {
    font-size: 24px;
    text-align: center;
  }
`;

const DescriptionForm = styled.p`
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  margin-top: 0;
  @media (max-width: 470px) {
    text-align: center;
    font-size: 13px;
    opacity: 0.8;
  }
`;

const Form = styled.form`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
`;

const StyledInput = styled.input`
  height: 15px;
  width: 80%;
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 0.8);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 15px;
  font-weight: 300;
  background: transparent;
  padding: 23px 25px;
  ::-webkit-input-placeholder {
    opacity: 0.4;
    color: #ffffff;
  }
`;

const StyledButton = styled.button`
  background-color: #008e8e;
  color: white;
  border-radius: 4px;
  outline: none;
  border: none;
  padding: 8px 42px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  margin: 8px;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`;

const NavFooter = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1280px) {
    max-width: 95%;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    max-width: 720px;
    margin: 60px auto 0;
  }
  @media (max-width: 470px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterColumn = styled.div`
  width: 20%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 25%;
  }
  @media (max-width: 470px) {
    max-width: 400px;
    width: 60%;
    text-align: center;
  }
`;

const Logo = styled.img`
  max-width: 85px;
  margin-bottom: 47px;
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 233px;
  @media (max-width: 1280px) {
    flex-wrap: wrap;
    max-width: 100px;
  }
  @media (max-width: 470px) {
    max-width: 250px;
  }
`;

const SocialIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43px;
  height: 43px;
  background-color: #008e8e;
  border-radius: 100%;
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
  img {
    max-width: 20px;
  }
`;

const TitleFooterNav = styled.h5`
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin-top: 32px;
  margin-bottom: 29px;
`;

const ListNavs = styled.ul`
  padding-left: 0;
  display: flex;
  flex-direction: column;
`;

const ListItem = styled(Link)`
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  margin-bottom: 12px;
  animation: all 0.2s ease-in-out;
  :hover {
    color: #008e8e;
  }
`;

const ContactItem = styled.div`
  margin-left: -20px;
  margin-top: 8px;
  display: flex;

  img {
    width: 17px;
    height: 22px;
  }
  .mail {
    height: 14px;
  }
  p {
    margin-top: 0;
  }
`;

const TextContact = styled.p`
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding-left: 20px;
  line-height: 28px;
  a {
    color: #ffffff;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-decoration: none;
  }
  @media (max-width: 470px) {
    a {
      padding-left: 20px;
    }
  }
`;

const FooterFooter = styled.div`
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 35px;
  @media (max-width: 1280px) {
    max-width: 95%;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    max-width: 720px;
    margin: 0 auto 0;
  }
  @media (max-width: 470px) {
    text-align: center;
  }
`;

const ContactIcon = styled.img`
  padding-top: 8px;
`;

export default Footer;
